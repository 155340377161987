<template>
     <CForm ref="form" class="needs-validation">
        <CRow>
            <CCol col="6">
                <CInput
                    label="İsim"
                    placeholder="Kampanya İsmi"
                    type="text"
                    v-model="data.name"
                    horizontal
                    required
                    pattern="[^ığüşöçĞÜŞÖÇİ]+" 
                />
            </CCol>
            <CCol col="6">
                <CInput
                    label="Tip"
                    placeholder="Kampanya Tipi"
                    type="text"
                    v-model="data.adcampType"
                    horizontal
                    required
                    pattern="[^ığüşöçĞÜŞÖÇİ]+" 
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol col="6">
                <CInput
                    label="Başlangıç Tarihi"
                    horizontal
                    type="datetime-local"
                    v-model="data.startTime"
                />
            </CCol>
            <CCol col="6">
                <CInput
                    label="Bitiş Tarihi"
                    horizontal
                    type="datetime-local"
                    v-model="data.finishTime"
                />
            </CCol>
        </CRow>
    </CForm>
</template>

<script>

    export default{
        name: "adCampForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {...this.params}
            },
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
    }
</script>